import React from "react";
import BlogPostsPageTemplate from "../../templates/blog-posts-page-template";
import { navigate } from "@reach/router";

const PublishedPosts = ({
    liked_posts: publishedPosts,
    pageContext,
    location,
}) => {
    publishedPosts = publishedPosts.map((post) => {
        return { node: post };
    });

    return (
        <React.Fragment>
            <BlogPostsPageTemplate
                title={"Published Posts"}
                posts={publishedPosts}
                pageContext={pageContext}
                location={location}
                isEditable={true}
            />
            <div className="mx-auto my-2 text-center">
                <button
                    className="btn btn-primary btn-lg"
                    onClick={(e) => navigate("/account/posts/new")}
                >
                    Create new
                </button>
            </div>
        </React.Fragment>
    );
};

export default PublishedPosts;
