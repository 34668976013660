import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/common/Layout";
import { auth } from "../utils/auth";
import CreatePost from "./create-post";
import CreateJob from "../custom-pages/account/create-job";
import LikedPosts from "../custom-pages/account/liked-posts";
import PublishedPosts from "../custom-pages/account/published-posts";
import Profile from "../custom-pages/account/profile";
import EditPost from "../custom-pages/edit-post";
import EditJobPost from "../custom-pages/edit-job-post";
import LoadingPage from "../custom-pages/loading-page";
import { hasPermissionsOf } from "../utils/helpers";
import PublishedJobs from "../custom-pages/account/published-jobs";

const Settings = () => {
    return <p>Settings</p>;
};

class Account extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
        };
    }

    componentDidMount() {
        this.loadUser();
    }

    loadUser = () => {
        if (auth.isAuthenticated()) {
            auth.getUser().then((user) => this.setState({ user }));
        }
    };

    render() {
        if (!auth.isAuthenticated()) {
            auth.login();
            return <LoadingPage />;
        }

        return (
            <React.Fragment>
                {this.state.user === undefined && <LoadingPage />}
                {this.state.user !== undefined && (
                    <Layout>
                        <Router>
                            <Profile
                                path="/account/profile"
                                user={this.state.user}
                            />
                            <Settings path="/account/settings" />

                            {/* Posts */}
                            {hasPermissionsOf(this.state.user, "Author") && (
                                <React.Fragment>
                                    <LikedPosts
                                        path="/account/posts/liked"
                                        liked_posts={
                                            this.state.user.liked_posts
                                        }
                                        location={this.props.location}
                                        pageContext={this.props.pageContext}
                                    />
                                    <PublishedPosts
                                        path="/account/posts/view"
                                        liked_posts={
                                            this.state.user.published_posts
                                        }
                                        location={this.props.location}
                                        pageContext={this.props.pageContext}
                                    />
                                    <EditPost
                                        path="/account/posts/posts/edit/:post_id"
                                        location={this.props.location}
                                        pageContext={this.props.pageContext}
                                    />
                                    <CreatePost path="/account/posts/new" />
                                </React.Fragment>
                            )}
                            {/* Jobs */}
                            {hasPermissionsOf(this.state.user, "Employer") && (
                                <React.Fragment>
                                    <CreateJob
                                        path="/account/jobs/new"
                                        user={this.state.user}
                                    />
                                    <PublishedJobs
                                        path="/account/jobs/view"
                                        published_jobs={
                                            this.state.user.published_jobs
                                        }
                                    />
                                    <EditJobPost
                                        path="/account/jobs/edit/:job_post_id"
                                        location={this.props.location}
                                        pageContext={this.props.pageContext}
                                    />
                                </React.Fragment>
                            )}
                        </Router>
                    </Layout>
                )}
            </React.Fragment>
        );
    }
}

export default Account;
