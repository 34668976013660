import React from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import LoadingPage from "../../custom-pages/loading-page";
import { backend } from "../../constants/backend";
import { UPDATE_USER_INFO } from "../../constants/mutations";
import { isBrowser } from "../../utils/helpers";

class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.countryOptions = countryList().getData();

        this.state = {
            name: undefined,
            email: undefined,
            phone: undefined,
            gender: undefined,
            country: undefined,
            state: undefined,
            city: undefined,
            address: undefined,
            zip_code: undefined,
            didLoad: false,
            didChange: false,
            options: this.countryOptions,
            isEditable: false,
        };
    }

    componentDidMount() {
        this.loadUserInfo();
    }

    loadUserInfo = () => {
        let info = this.props.user.info;
        this.setState({ ...this.props.user, ...info, didLoad: true });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.didLoad && (
                    <div className="bg-light site-main max-height">
                        {/* Content Section */}
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col col-9">
                                    {/* Card */}
                                    <div className="card mb-3 mb-lg-5">
                                        <div className="card-header">
                                            <h3 className="card-title">
                                                Profile
                                            </h3>
                                            <i
                                                className="fas fa-edit clickable"
                                                onClick={() =>
                                                    this.setState({
                                                        isEditable: true,
                                                    })
                                                }
                                            ></i>
                                        </div>
                                        {/* Body */}
                                        <div className="card-body">
                                            {/* {this.renderProfilePicture()} */}
                                            <form>
                                                {/* Form Group */}
                                                <div className="row form-group">
                                                    <label
                                                        htmlFor="firstNameLabel"
                                                        className="col-sm-3 col-form-label input-label"
                                                    >
                                                        Name
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter your name"
                                                                defaultValue={
                                                                    this.state
                                                                        .name
                                                                }
                                                                onChange={(e) =>
                                                                    this.updateProfileInfo(
                                                                        "name",
                                                                        e
                                                                            .currentTarget
                                                                            .value
                                                                    )
                                                                }
                                                                disabled={
                                                                    !this.state
                                                                        .isEditable
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* End Form Group */}
                                                {/* Form Group */}
                                                <div className="row form-group">
                                                    <label
                                                        htmlFor="emailLabel"
                                                        className="col-sm-3 col-form-label input-label"
                                                    >
                                                        Email
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <input
                                                            type="email"
                                                            className="form-control text-muted"
                                                            name="email"
                                                            id="emailLabel"
                                                            placeholder="Enter your email"
                                                            defaultValue={
                                                                this.state.email
                                                            }
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                {/* End Form Group */}
                                                {/* Form Group */}
                                                <div className="row form-group">
                                                    <label
                                                        htmlFor="phoneLabel"
                                                        className="col-sm-3 col-form-label input-label"
                                                    >
                                                        Phone number{" "}
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <div className="input-group align-items-center">
                                                            <input
                                                                type="tel"
                                                                className="js-masked-input form-control"
                                                                placeholder="Enter your phone number"
                                                                defaultValue={
                                                                    this.state
                                                                        .phone
                                                                }
                                                                onChange={(e) =>
                                                                    this.updateProfileInfo(
                                                                        "phone",
                                                                        e
                                                                            .currentTarget
                                                                            .value
                                                                    )
                                                                }
                                                                disabled={
                                                                    !this.state
                                                                        .isEditable
                                                                }
                                                            />
                                                        </div>
                                                        {/* Container For Input Field */}
                                                        <div id="addPhoneFieldContainer" />
                                                    </div>
                                                </div>
                                                {/* End Form Group */}

                                                {/* Form Group */}
                                                <div className="row form-group">
                                                    <label className="col-sm-3 col-form-label input-label">
                                                        Gender
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <div className="input-group input-group-md-down-break">
                                                            {/* Custom Radios */}
                                                            {this.renderRadio(
                                                                "Male",
                                                                this.state
                                                                    .gender ===
                                                                    "Male"
                                                            )}
                                                            {this.renderRadio(
                                                                "Female",
                                                                this.state
                                                                    .gender ===
                                                                    "Female"
                                                            )}
                                                            {this.renderRadio(
                                                                "Other",
                                                                this.state
                                                                    .gender ===
                                                                    "Other"
                                                            )}
                                                            {/* End Custom Radios */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* End Form Group */}

                                                {/* Form Group */}
                                                <div className="row form-group">
                                                    <label
                                                        htmlFor="locationLabel"
                                                        className="col-sm-3 col-form-label input-label"
                                                    >
                                                        Location
                                                    </label>
                                                    <div className="col-sm-9">
                                                        {/* Select */}
                                                        <div className="mb-3">
                                                            <Select
                                                                isDisabled={
                                                                    !this.state
                                                                        .isEditable
                                                                }
                                                                options={
                                                                    this.state
                                                                        .options
                                                                }
                                                                value={
                                                                    this.state
                                                                        .value
                                                                }
                                                                onChange={(e) =>
                                                                    this.updateProfileInfo(
                                                                        "country",
                                                                        e.label
                                                                    )
                                                                }
                                                                defaultValue={{
                                                                    label: this
                                                                        .state
                                                                        .country,
                                                                    value: this
                                                                        .state
                                                                        .country,
                                                                }}
                                                                placeholder="Select your country.."
                                                                disabled={
                                                                    !this.state
                                                                        .isEditable
                                                                }
                                                            />
                                                        </div>
                                                        {/* End Select */}
                                                        <div className="mb-3">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter your state"
                                                                defaultValue={
                                                                    this.state
                                                                        .state
                                                                }
                                                                onChange={(e) =>
                                                                    this.updateProfileInfo(
                                                                        "state",
                                                                        e
                                                                            .currentTarget
                                                                            .value
                                                                    )
                                                                }
                                                                disabled={
                                                                    !this.state
                                                                        .isEditable
                                                                }
                                                            />
                                                        </div>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={
                                                                "Enter your city"
                                                            }
                                                            defaultValue={
                                                                this.state.city
                                                            }
                                                            onChange={(e) =>
                                                                this.updateProfileInfo(
                                                                    "city",
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            disabled={
                                                                !this.state
                                                                    .isEditable
                                                            }
                                                        />
                                                    </div>
                                                </div>

                                                {/* End Form Group */}
                                                {/* Form Group */}
                                                <div className="row form-group">
                                                    <label className="col-sm-3 col-form-label input-label">
                                                        Street address
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your address"
                                                            defaultValue={
                                                                this.state
                                                                    .address
                                                            }
                                                            onChange={(e) =>
                                                                this.updateProfileInfo(
                                                                    "address",
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            disabled={
                                                                !this.state
                                                                    .isEditable
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* End Form Group */}

                                                {/* Form Group */}
                                                <div className="row form-group">
                                                    <label
                                                        htmlFor="zipCodeLabel"
                                                        className="col-sm-3 col-form-label input-label"
                                                    >
                                                        Zip code{" "}
                                                    </label>
                                                    <div className="col-sm-9">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter your zip code"
                                                            onChange={(e) =>
                                                                this.updateProfileInfo(
                                                                    "zip_code",
                                                                    e
                                                                        .currentTarget
                                                                        .value
                                                                )
                                                            }
                                                            defaultValue={
                                                                this.state
                                                                    .zip_code
                                                            }
                                                            disabled={
                                                                !this.state
                                                                    .isEditable
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                {/* End Form Group */}
                                            </form>
                                        </div>
                                        {/* End Body */}
                                        {/* Footer */}
                                        {this.state.didChange &&
                                            this.renderChangesMenu()}

                                        {/* End Footer */}
                                    </div>
                                    {/* End Card */}
                                </div>
                            </div>
                            {/* End Row */}
                        </div>
                        {/* End Content Section */}
                    </div>
                )}
                {!this.state.didLoad && <LoadingPage />}
            </React.Fragment>
        );
    }

    renderProfilePicture = () => {
        return (
            <div className="mb-5 text-center">
                <img src={this.props.user.picture} />
            </div>
        );
    };

    updateProfileInfo = (property, value) => {
        this.setState((state) => {
            state[property] = value;
            state.didChange = true;
            return state;
        });
    };

    renderRadio = (label, defaultChecked) => {
        let id = "radioFor" + label;
        return (
            <div className="form-control">
                <div className="form-check">
                    <input
                        type="radio"
                        className="form-check-input"
                        id={id}
                        value={label}
                        defaultChecked={defaultChecked}
                        name="gender-radios"
                        onChange={(e) =>
                            this.updateProfileInfo("gender", label)
                        }
                        disabled={!this.state.isEditable}
                    />
                    <label className="form-check-label ml-2" htmlFor={id}>
                        {label}
                    </label>
                </div>
            </div>
        );
    };

    renderChangesMenu = () => {
        return (
            <div className="card-footer d-flex justify-content-end">
                <a className="btn btn-white" onClick={this.refreshPage}>
                    Discard changes
                </a>
                <span className="mx-2" />
                <a
                    className="btn btn-primary text-white"
                    onClick={this.saveChanges}
                >
                    Save changes
                </a>
            </div>
        );
    };

    saveChanges = () => {
        backend
            .mutate({
                mutation: UPDATE_USER_INFO,
                variables: {
                    auth0_id: this.props.user.sub,
                    ...this.state,
                },
            })
            .then(() => {
                this.refreshPage();
            })
            .catch((e) => {
                console.error(e);
                alert("An erorr occurred. Please try again later.");
            });
    };

    refreshPage = () => {
        if (isBrowser) {
            location.reload();
        }
    };
}

export default Profile;
