import React from "react";
import BlogPostsPageTemplate from "../../templates/blog-posts-page-template";

const LikedPosts = ({ liked_posts: likedPosts, pageContext, location }) => {
    likedPosts = likedPosts.map((post) => {
        return { node: post };
    });

    return (
        <BlogPostsPageTemplate
            title={"Liked Posts"}
            posts={likedPosts}
            pageContext={pageContext}
            location={location}
        />
    );
};

export default LikedPosts;
