import React from "react";
import { backend } from "../constants/backend";
import { GET_POST_BY_ID } from "../constants/queries";
import CreatePost from "../pages/create-post";

class EditPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.getPostData();
    }

    getPostData = () => {
        backend
            .query({
                query: GET_POST_BY_ID,
                variables: { post_id: this.props.post_id },
                fetchPolicy: "network-only",
            })
            .then((result) => result.data.get_post_by_id)
            .then((post) => {
                post.tags = post.tags.map((tag) => {
                    if (typeof tag == "string") return tag;
                    return tag.name;
                });
                this.setState({ post });
            })
            .catch((e) => {
                console.error(e);
                alert("An error occurred. Please try again later.");
            });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.post != undefined && (
                    <CreatePost
                        post_id={this.props.post_id}
                        content={this.state.post.html}
                        tags={this.state.post.tags}
                        title={this.state.post.title}
                        cover={this.state.post.feature_image}
                        isEditing={true}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default EditPost;
