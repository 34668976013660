import React from "react";
import { backend } from "../constants/backend";
import { GET_JOB_BY_ID } from "../constants/queries";
import CreateJob from "./account/create-job";

class EditJobPost extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.getJobPostData();
    }

    getJobPostData = () => {
        backend
            .query({
                query: GET_JOB_BY_ID,
                variables: { id: this.props.job_post_id },
                fetchPolicy: "network-only",
            })
            .then((result) => result.data.get_job_by_id)
            .then((post) => {
                this.setState({ post }, () => console.log(this.state));
            })
            .catch((e) => {
                console.error(e);
                alert("An error occurred. Please try again later.");
            });
    };

    render() {
        return (
            <React.Fragment>
                {this.state.post != undefined && (
                    <CreateJob {...this.state.post} isEditing={true} />
                )}
            </React.Fragment>
        );
    }
}

export default EditJobPost;
