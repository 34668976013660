import React from "react";
import { navigate } from "@reach/router";
import { Row } from "reactstrap";
import JobCard from "../../components/job-card";

const PublishedJobs = ({ published_jobs }) => {
    return (
        <React.Fragment>
            <div className="w-75 mx-auto mt-3">
                <h1 className="special-heading ">Published Jobs</h1>
                <hr />
            </div>
            <Row className="post-feed w-50 mx-auto">
                {published_jobs.map((job) => {
                    return <JobCard {...job} isEditable={true} />;
                })}
            </Row>
            <div className="mx-auto my-2 text-center">
                <button
                    className="btn btn-primary btn-lg"
                    onClick={(e) => navigate("/account/jobs/new")}
                >
                    Create new
                </button>
            </div>
        </React.Fragment>
    );
};

export default PublishedJobs;
