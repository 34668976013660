import React from "react";
import { FormGroup, Row, Col } from "reactstrap";
import { backend } from "../../constants/backend";
import { isBrowser } from "../../utils/helpers";
import { INSERT_JOB, UPDATE_JOB } from "../../constants/mutations";

class CreateJob extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.isEditing ? Number(this.props.id) : undefined,
            title: this.props.isEditing ? this.props.title : undefined,
            company: this.props.isEditing ? this.props.company : undefined,
            location: this.props.isEditing ? this.props.location : undefined,
            description: this.props.isEditing
                ? this.props.description
                : undefined,
            min_salary: this.props.isEditing
                ? this.props.min_salary
                : undefined,
            max_salary: this.props.isEditing
                ? this.props.max_salary
                : undefined,
            industries: this.props.isEditing
                ? this.props.industries
                : undefined,
            type: this.props.isEditing ? this.props.type : "Full-Time",
            job_functions: this.props.isEditing
                ? this.props.job_functions
                : undefined,
            contact_email: this.props.isEditing
                ? this.props.contact_email
                : undefined,
            employer_auth0_id: this.props.isEditing
                ? this.props.employer_auth0_id
                : this.props.user.sub,
        };
    }

    render() {
        return (
            <div className="w-50 mx-auto my-5 text-center">
                <form onSubmit={this.submitForm}>
                    <h1 className="mb-4 text-center">
                        {this.props.isEditing
                            ? "Editing Job Posting"
                            : "New Job Posting"}
                    </h1>
                    {this.renderInputs()}

                    <button className="btn btn-primary btn-lg" type="submit">
                        Submit
                    </button>
                </form>
            </div>
        );
    }

    renderInputs = () => {
        return (
            <React.Fragment>
                <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="Contact email"
                    onChange={(e) =>
                        this.updateState(e.currentTarget.value, "contact_email")
                    }
                    defaultValue={this.state.contact_email}
                />
                <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="Job title"
                    onChange={(e) =>
                        this.updateState(e.currentTarget.value, "title")
                    }
                    defaultValue={this.state.title}
                />
                <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="Company name"
                    onChange={(e) =>
                        this.updateState(e.currentTarget.value, "company")
                    }
                    defaultValue={this.state.company}
                />
                <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="Location (e.g. city,state,country)"
                    onChange={(e) =>
                        this.updateState(e.currentTarget.value, "location")
                    }
                    defaultValue={this.state.location}
                />
                <textarea
                    className="form-control rounded-0 mb-4"
                    rows={5}
                    placeholder="Job description"
                    onChange={(e) =>
                        this.updateState(e.currentTarget.value, "description")
                    }
                    defaultValue={this.state.description}
                />
                <FormGroup>
                    <Row>
                        <Col>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Min. estimated salary"
                                onChange={(e) =>
                                    this.updateState(
                                        Number(e.currentTarget.value),
                                        "min_salary"
                                    )
                                }
                                defaultValue={this.state.min_salary}
                            />
                        </Col>
                        <Col>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="Max. estimated salary"
                                onChange={(e) =>
                                    this.updateState(
                                        Number(e.currentTarget.value),
                                        "max_salary"
                                    )
                                }
                                defaultValue={this.state.max_salary}
                            />
                        </Col>
                    </Row>
                </FormGroup>

                <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="Industries"
                    onChange={(e) =>
                        this.updateState(e.currentTarget.value, "industries")
                    }
                    defaultValue={this.state.industries}
                />

                <select
                    className="form-control mb-4"
                    onChange={(e) =>
                        this.updateState(e.currentTarget.value, "type")
                    }
                    defaultValue={this.state.type}
                >
                    <option disabled>Employment type</option>
                    <option>Full-Time</option>
                    <option>Part-Time</option>
                </select>

                <input
                    type="text"
                    className="form-control mb-4"
                    placeholder="Job functions"
                    onChange={(e) =>
                        this.updateState(e.currentTarget.value, "job_functions")
                    }
                    defaultValue={this.state.job_functions}
                />
            </React.Fragment>
        );
    };

    updateState = (newValue, propertyName) => {
        this.setState((state) => {
            state[propertyName] = newValue;
            return state;
        });
    };

    submitForm = (e) => {
        e.preventDefault();

        backend
            .mutate({
                mutation: this.props.isEditing ? UPDATE_JOB : INSERT_JOB,
                variables: { ...this.state },
            })
            .then(() => {
                alert(
                    "Your job was submitted for review and should be live shortly."
                );
                if (isBrowser) {
                    window.location.reload();
                }
            });
    };
}

export default CreateJob;
